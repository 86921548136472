import React, {useEffect, useState} from "react";
import {Link, Redirect} from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import * as Sentry from "@sentry/browser";
import environment, {standardResponseHandler} from "../../services/graphql";
import Config from "../../services/config";
import {LoginWrapper} from "./LoginForm";
import {useDispatch} from "react-redux";
import {Button, Typography} from "@material-ui/core";


const RedeemToken = ({match}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({message: 'Validating token...'})
  const key = match?.params?.key
  useEffect(() => {
    if (key) {
      redeemToken(dispatch, key, setState)
    }
  }, [key, dispatch])
  if (state.message) {
    Sentry.captureMessage(`Token Redemption Error: ${state.message}`);
    return (
      <LoginWrapper>
        <Typography gutterBottom>{state.message}</Typography>
        <Link to="/" component={Button}>Log in again</Link>
      </LoginWrapper>
    )
  }
  if (state.ok) {
    return <Redirect to="/" />
  }
  return <LoginWrapper>Loading...</LoginWrapper>
};


const redeemTokenMutation = graphql`
  mutation RedeemTokenMutation($token: String!) {
    redeemToken(token: $token) {
      token
    }
  }
`;

const redeemToken = (dispatch, token, setState) => {
  return commitMutation(environment, {
    ...standardResponseHandler(dispatch),
    onCompleted: (response, errors) => {
      if (errors) {
        console.error("Token errors", errors)
        setState({message: errors[0]?.message})
      } else {
        console.log("Got token", response)
        localStorage.setItem(Config.LOCAL_STORAGE_KEY, response.redeemToken.token)
        setState({ok: true})
      }
    },
    mutation: redeemTokenMutation,
    variables: {token},
  });
}

export default RedeemToken;