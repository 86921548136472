import React, {useState} from "react";
import {Button, Grid, Paper, TextField} from "@material-ui/core";
import {commitMutation} from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import environment, {standardResponseHandler} from "../../services/graphql";
import {calculateUrlState} from "../../helpers/urlStateHook";
import {useLocation} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {identify} from "../../helpers/gosquared";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%'
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: '35ch',

  }
}));

export const LoginWrapper = ({children}) => {
  const classes = useStyles();
  return (
      <Grid container align="center" justify="center" alignItems="center">
        <Grid item md={6}>
          <Paper className={classes.paper}>
            {children}
          </Paper>
        </Grid>
      </Grid>
  )
}

const LoginForm = () => {
  const classes = useStyles();
  const [messageSent, setMessageSent] = useState(null);
  const initial = calculateUrlState(useLocation().search);
  const [email, setEmail] = useState(initial.email ? initial.email : '');
  const [code, setCode] = useState(initial.code ? initial.code : '');
  if (messageSent) {
    return <LoginWrapper><Typography>{messageSent}</Typography></LoginWrapper>
  }
  return (
      <LoginWrapper>
        <Typography component="h2" variant="h6" gutterBottom>Team Feedback</Typography>
        <Typography gutterBottom align="justify">
          Thank for offering to provide feedback on our team. You should have received a code
          that identifies the feedback you have agreed to provide. Please enter your email and the code
          below to receive a sign-in link.
        </Typography>
        <Typography gutterBottom align="justify">
          If you have already registered, you only need to provide your email address.
        </Typography>
        <Typography gutterBottom align="justify">
          The link is only valid once and has to be used within 15 minutes. A small value (a cookie) will
          be stored in your browser so that you don't have to log in again.
        </Typography>
        <Typography gutterBottom align="justify">
          However, if you switch computer,
          or the cookie is lost, please come back to this page and enter your email. You do not need the code
          once it has been linked to your email.
        </Typography>
        <form noValidate autoComplete="off">
          <div>
            <TextField className={classes.textField} label="Your Email" value={email}
                       onChange={e => setEmail(e.target.value)}/>
          </div>
          <div>
            <TextField className={classes.textField} label="Invitation Code" value={code}
                       onChange={e => setCode(e.target.value)}/>
          </div>
          <Button variant="contained" color="primary"
                  onClick={() => sendToken(email, code, setMessageSent)}>Submit</Button>
        </form>
      </LoginWrapper>

  )
}
;


const sendTokenMutation = graphql`
mutation LoginFormSendTokenMutation($email: String!, $code: Int)
{
  sendToken(email
:
  $email, code
:
  $code
)
  {
    ok
  }
}
`;

const sendToken = (email, code, callback) =>
{
  code = parseInt(code)
  identify({email})
  return commitMutation(environment, {
    ...standardResponseHandler(),
    onCompleted: (response, errors) => {
      if (errors) {
        console.error("Errors", errors)
        callback("Registration failed. Please contact Social Finance for a new code.")
      } else {
        console.log("Response OK")
        callback("Registration processed. You should be receiving an email shortly.")
      }
    },
    mutation: sendTokenMutation,
    variables: {email, code},
  });
}

export default LoginForm;