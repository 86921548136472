import * as Sentry from "@sentry/browser";

export const identify = (props) => {
    try {
        if (window._gs) {
            window._gs('identify', props);
        }
        try {
            if (props.email) {
                Sentry.configureScope( scope => scope.setUser(props.email));
            }
        } catch (err) {}
    } catch (err) {
        Sentry.captureException(err);
    }
}

export const unidentify = () => {
    try {
        if (window._gs) {
            window._gs('unidentify');
        }
    } catch (err) {
        Sentry.captureException(err);
    }
}

export const track = () => {
        try {
            if (window._gs) {
                window._gs('track');
            }
        } catch (err) {
            Sentry.captureException(err);
        }
};