import React from "react";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import gfm from "remark-gfm";
import emoji from "remark-emoji";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  inlineLink: {
    borderBottom: '1px dotted grey',
    color: 'inherit',
    textDecoration: 'none',
    padding: '0 .2em',
    cursor: "pointer",
    fontWeight: 600,
  },
}));

export const StyledLink = ({href, node, ...props}) => {
    const classes = useStyles();
    if (node?.url.startsWith("to:")) {
      const url = node.url.substr(3);
      return <RouterLink className={classes.inlineLink} to={url} {...props}/>
    } else {
      return <Link className={classes.inlineLink} href={href} target="_blank" {...props}/>
    }
};

const MarkdownBlock = ({children, renderers, pVariant}) => {
  const default_renderers = {
    heading: ({level, children}) => {
      return <Typography component={`h${level}`} variant={`h${level+4}`} gutterBottom >{children}</Typography>
    },
    link: ({children, ...props}) => {
      return <StyledLink {...props} >{children}</StyledLink>
    },
    paragraph: ({children}) => {
      return <Typography variant={pVariant ? pVariant : 'body2'} gutterBottom>{children}</Typography>
    },
  }
  return <ReactMarkdown plugins={[gfm, emoji]} renderers={{...default_renderers, ...renderers}}>{children}</ReactMarkdown>;
}

export default MarkdownBlock;