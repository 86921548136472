/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FeedbackItem_toReview$ref = any;
export type FeedbackWidgetQueryVariables = {||};
export type FeedbackWidgetQueryResponse = {|
  +reviews: ?{|
    +nominations: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +reviewee: ?{|
            +firstName: string,
            +lastName: string,
          |},
          +$fragmentRefs: FeedbackItem_toReview$ref,
        |}
      |}>
    |}
  |}
|};
export type FeedbackWidgetQuery = {|
  variables: FeedbackWidgetQueryVariables,
  response: FeedbackWidgetQueryResponse,
|};
*/


/*
query FeedbackWidgetQuery {
  reviews: myReviews {
    nominations {
      edges {
        node {
          id
          reviewee {
            firstName
            lastName
            id
          }
          ...FeedbackItem_toReview
        }
      }
    }
    id
  }
}

fragment FeedbackFormQuestion_question on ReviewFormQuestionNode {
  id
  title
  description
}

fragment FeedbackForm_form on ReviewFormNode {
  id
  role
  description
  questions {
    edges {
      node {
        id
        ...FeedbackFormQuestion_question
      }
    }
  }
}

fragment FeedbackForm_responses on ReviewFormResponseNodeConnection {
  edges {
    node {
      question {
        id
      }
      value
      id
    }
  }
}

fragment FeedbackItem_toReview on ExternalReviewerNominationNode {
  id
  reviewee {
    firstName
    lastName
    id
  }
  form {
    id
    title
    ...FeedbackForm_form
  }
  responses {
    ...FeedbackForm_responses
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackWidgetQuery",
    "selections": [
      {
        "alias": "reviews",
        "args": null,
        "concreteType": "ExternalReviewerNode",
        "kind": "LinkedField",
        "name": "myReviews",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalReviewerNominationConnection",
            "kind": "LinkedField",
            "name": "nominations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalReviewerNominationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalReviewerNominationNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalUserNode",
                        "kind": "LinkedField",
                        "name": "reviewee",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "FeedbackItem_toReview"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FeedbackWidgetQuery",
    "selections": [
      {
        "alias": "reviews",
        "args": null,
        "concreteType": "ExternalReviewerNode",
        "kind": "LinkedField",
        "name": "myReviews",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalReviewerNominationConnection",
            "kind": "LinkedField",
            "name": "nominations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalReviewerNominationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalReviewerNominationNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalUserNode",
                        "kind": "LinkedField",
                        "name": "reviewee",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReviewFormNode",
                        "kind": "LinkedField",
                        "name": "form",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReviewFormQuestionNodeConnection",
                            "kind": "LinkedField",
                            "name": "questions",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReviewFormQuestionNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReviewFormQuestionNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v0/*: any*/),
                                      (v3/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReviewFormResponseNodeConnection",
                        "kind": "LinkedField",
                        "name": "responses",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReviewFormResponseNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ReviewFormResponseNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReviewFormQuestionNode",
                                    "kind": "LinkedField",
                                    "name": "question",
                                    "plural": false,
                                    "selections": [
                                      (v0/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "value",
                                    "storageKey": null
                                  },
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "FeedbackWidgetQuery",
    "operationKind": "query",
    "text": "query FeedbackWidgetQuery {\n  reviews: myReviews {\n    nominations {\n      edges {\n        node {\n          id\n          reviewee {\n            firstName\n            lastName\n            id\n          }\n          ...FeedbackItem_toReview\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment FeedbackFormQuestion_question on ReviewFormQuestionNode {\n  id\n  title\n  description\n}\n\nfragment FeedbackForm_form on ReviewFormNode {\n  id\n  role\n  description\n  questions {\n    edges {\n      node {\n        id\n        ...FeedbackFormQuestion_question\n      }\n    }\n  }\n}\n\nfragment FeedbackForm_responses on ReviewFormResponseNodeConnection {\n  edges {\n    node {\n      question {\n        id\n      }\n      value\n      id\n    }\n  }\n}\n\nfragment FeedbackItem_toReview on ExternalReviewerNominationNode {\n  id\n  reviewee {\n    firstName\n    lastName\n    id\n  }\n  form {\n    id\n    title\n    ...FeedbackForm_form\n  }\n  responses {\n    ...FeedbackForm_responses\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e4c1d963228c667f50cba050026b33fe';

module.exports = node;
