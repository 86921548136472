import React from "react";
import graphql from 'babel-plugin-relay/macro';
import environment, {standardResponseComponent} from "../services/graphql";
import {QueryRenderer} from 'react-relay';
import {
  Redirect,
} from "react-router-dom";
import FeedbackWidget from "../components/reviews/FeedbackWidget";
import {identify} from "../helpers/gosquared";


const AuthRouter = ({authUser}) => {
  const userEmail = authUser?.email;
  if (userEmail) {
    identify({email: userEmail})
    return <FeedbackWidget />
  } else {
    return <Redirect to="/login"/>
  }

}

const AuthRouterQuery = () => {
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query AuthRouterQuery {
            authUser {
              email
            }
          }
        `}
        variables={{}}
        render={standardResponseComponent(AuthRouter)}
      />
    );
};

export default AuthRouterQuery;