
export const getMeta = (name, defaultValue=null) => {
  const metaTag = document.head.querySelector(`meta[name=${name} i]`)
  if (metaTag) {
    const content = metaTag.content;
    if (content && !content.startsWith('++')) {
      return content;
    }
  }
  return defaultValue;
}

export const HEROKU_RELEASE_VERSION = getMeta('release')

