import React from "react";
import MarkdownBlock from "../common/MarkdownBlock";
import {createFragmentContainer} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import FeedbackFormQuestion from "./FeedbackFormQuestion";

const FeedbackForm = ({form, responses, nominationId, tick, setCounter}) => {
  const responseMap = responses.edges.reduce((acc, value) => {
    acc[value.node.question.id] = value.node;
    return acc
  }, {})
  return (
    <div style={{width: '100%', flexDirection: 'row'}}>
      <MarkdownBlock>{form?.description}</MarkdownBlock>
      { form.questions.edges.map((edge, ix) => <FeedbackFormQuestion
          key={edge.node.id} seq={ix} question={edge.node} initialValue={responseMap[edge.node.id]?.value}
          nominationId={nominationId} tick={tick} setCounter={setCounter}
      />)}
    </div>
  )
}

export default createFragmentContainer(FeedbackForm, {
  form: graphql`
    fragment FeedbackForm_form on ReviewFormNode {
      id
      role
      description
      questions {
        edges {
          node {
            id
            ...FeedbackFormQuestion_question
          }
        }
      }
    }
  `,
  responses: graphql`
      fragment FeedbackForm_responses on ReviewFormResponseNodeConnection {
        edges {
          node {
            question {
              id
            }
            value
          }
        }
      }
  `,
});