import {
  Environment,
  Network,
  RecordSource,
  Store,
} from 'relay-runtime';
import Config from "./config";
import React from "react";
import {setSnack} from "../actions/snack";

export const standardResponseComponent = (WrappedComponent, widgetProps = {}) => {
  return ({error, props}) => {
    if (error) {
      return <div>Error!</div>;
    }
    if (!props) {
      return <div>Loading...</div>;
    }
    return <WrappedComponent {...widgetProps} {...props} />
  }
}

export const standardResponseHandler = (dispatch, props = {}) => {
  return {
    onCompleted: (response, errors) => {
      if (errors) {
        console.error("Errors", errors)
        dispatch(setSnack("Oh no, something went wrong whilst saving.", 'error'))
      } else {
        console.log("Response OK")
        dispatch(setSnack(props.ok ? props.ok : "Saved"))
      }
    },
    onError: err => {
      console.error('standardResponseHandler:', err)
      dispatch(setSnack("Something went wrong", 'error'))
    },
  }
}

function fetchQuery(
  operation,
  variables,
) {

  const authHeaders = {};
  const token = localStorage.getItem(Config.LOCAL_STORAGE_KEY)
  if (token) {
    authHeaders['Authorization'] = `Bearer ${token}`
  }
  return fetch(`${Config.API_ROOT}/public/graphql`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then(response => {
    const refreshHeader = response.headers.get('x-authorization-refresh');
    if (refreshHeader) {
      localStorage.setItem(Config.LOCAL_STORAGE_KEY, refreshHeader)
    }
    return response.json();
  });
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});

export default environment;
