import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

export const CreditKaj = () => (
      <Link color="inherit" href="https://github.com/kws">
        Kaj
      </Link>
)


const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Made with ❤️ by '}<CreditKaj />{'.'}
    </Typography>
  );
};

export default Copyright;