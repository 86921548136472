/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeedbackFormQuestionMutationVariables = {|
  nominationId: string,
  questionId: string,
  value: string,
|};
export type FeedbackFormQuestionMutationResponse = {|
  +updateReviewResponse: ?{|
    +response: ?{|
      +nomination: {|
        +id: string
      |}
    |}
  |}
|};
export type FeedbackFormQuestionMutation = {|
  variables: FeedbackFormQuestionMutationVariables,
  response: FeedbackFormQuestionMutationResponse,
|};
*/


/*
mutation FeedbackFormQuestionMutation(
  $nominationId: ID!
  $questionId: ID!
  $value: String!
) {
  updateReviewResponse(input: {nominationId: $nominationId, questionId: $questionId, value: $value}) {
    response {
      nomination {
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nominationId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "questionId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value",
    "type": "String!"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "nominationId",
        "variableName": "nominationId"
      },
      {
        "kind": "Variable",
        "name": "questionId",
        "variableName": "questionId"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "NominationNode",
  "kind": "LinkedField",
  "name": "nomination",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackFormQuestionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExternalResponseUpdateMutationPayload",
        "kind": "LinkedField",
        "name": "updateReviewResponse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewFormResponseNode",
            "kind": "LinkedField",
            "name": "response",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeedbackFormQuestionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExternalResponseUpdateMutationPayload",
        "kind": "LinkedField",
        "name": "updateReviewResponse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewFormResponseNode",
            "kind": "LinkedField",
            "name": "response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "FeedbackFormQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation FeedbackFormQuestionMutation(\n  $nominationId: ID!\n  $questionId: ID!\n  $value: String!\n) {\n  updateReviewResponse(input: {nominationId: $nominationId, questionId: $questionId, value: $value}) {\n    response {\n      nomination {\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ff1f4f630abba86fdbbc552b35c70f9e';

module.exports = node;
