/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AuthRouterQueryVariables = {||};
export type AuthRouterQueryResponse = {|
  +authUser: ?{|
    +email: string
  |}
|};
export type AuthRouterQuery = {|
  variables: AuthRouterQueryVariables,
  response: AuthRouterQueryResponse,
|};
*/


/*
query AuthRouterQuery {
  authUser {
    email
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthRouterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthUserQuery",
        "kind": "LinkedField",
        "name": "authUser",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthRouterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthUserQuery",
        "kind": "LinkedField",
        "name": "authUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AuthRouterQuery",
    "operationKind": "query",
    "text": "query AuthRouterQuery {\n  authUser {\n    email\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '475bf94985f6f87e98c543a21ec76b4a';

module.exports = node;
