/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FeedbackFormQuestion_question$ref = any;
export type ReviewFormRole = "DR" | "EX" | "PM" | "SA" | "WT" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedbackForm_form$ref: FragmentReference;
declare export opaque type FeedbackForm_form$fragmentType: FeedbackForm_form$ref;
export type FeedbackForm_form = {|
  +id: string,
  +role: ReviewFormRole,
  +description: ?string,
  +questions: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: FeedbackFormQuestion_question$ref,
      |}
    |}>
  |},
  +$refType: FeedbackForm_form$ref,
|};
export type FeedbackForm_form$data = FeedbackForm_form;
export type FeedbackForm_form$key = {
  +$data?: FeedbackForm_form$data,
  +$fragmentRefs: FeedbackForm_form$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedbackForm_form",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReviewFormQuestionNodeConnection",
      "kind": "LinkedField",
      "name": "questions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReviewFormQuestionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReviewFormQuestionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "FeedbackFormQuestion_question"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ReviewFormNode"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f7c655db086662aad7b7e753bc7771ef';

module.exports = node;
