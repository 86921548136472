import React from "react";
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import * as Sentry from "@sentry/react";

import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import {Provider} from "react-redux";
import AuthRouter from "./auth/AuthRouter";
import {HashRouter as Router, Switch, Route} from "react-router-dom";
import LoginForm from "./components/auth/LoginForm";
import RedeemToken from "./components/auth/RedeemToken";
import Layout from "./components/layout/Layout";

const loggerMiddleware = createLogger();
const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware, // lets us dispatch() functions
        loggerMiddleware // neat middleware that logs actions
    )
);

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#6c3674',
            light: '#9c62a3',
            dark: '#3f0948',
            contrastText: '#fff',
        },
        secondary: {
            main: '#c5006f',
            light: '#fc509d',
            dark: '#8f0044',
            contrastText: '#fff',
        },
    },
});

function App() {
    return (
        <>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Layout>
                        <Switch>
                            <Route path="/login">
                                <LoginForm />
                            </Route>
                            <Route path="/key/:key" component={RedeemToken} />
                            <Route>
                                <AuthRouter/>
                            </Route>
                        </Switch>
                    </Layout>
                </Router>
            </ThemeProvider>
          </Provider>
        </>
    );
}

export default Sentry.withProfiler(App);
