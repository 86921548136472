import {SET_SNACK, CLEAR_SNACK} from "../actions/snack";

const snack = (state = {}, action) => {
  switch (action.type) {
    case SET_SNACK:
      return { msg: action.msg, severity: action.severity };
    case CLEAR_SNACK:
      return { };
    default:
      return state;
  }
};

export default snack;
