
export const SET_SNACK = "SET_SNACK";
export const setSnack = (msg, severity="success") => dispatch => {
    return dispatch({
        type: SET_SNACK,
        msg,
        severity,
    });
}

export const CLEAR_SNACK = "CLEAR_SNACK";
export const clearSnack = () => dispatch => {
    return dispatch({
        type: CLEAR_SNACK
    });
}

