import React, {useEffect, useState} from "react";
import {Prompt} from "react-router-dom";

import {QueryRenderer} from "react-relay";
import environment, {standardResponseComponent} from "../../services/graphql";
import graphql from "babel-plugin-relay/macro";
import Typography from "@material-ui/core/Typography";
import MarkdownBlock from "../common/MarkdownBlock";
import FeedbackItem from "./FeedbackItem";
import useInterval from "../../helpers/useInterval";
import {useEditors} from "./questionHook";
import {Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%'
    },
}));

const username = (edge) => {
  return edge.node.reviewee.firstName.toLowerCase();
}

const FeedbackWidget = ({reviews}) => {
  const classes = useStyles();
  const [tick, setTick] = useState(0);
  const nominations = [...reviews.nominations.edges].sort((e1,e2) => username(e1).localeCompare(username(e2)))

  useInterval(() => setTick(tick+1),  1 * 1000);
  const editors = useEditors();

  const isChanged = () => editors.editors().reduce((acc, editor) => editor.changed || acc, false)

  useEffect(() => {
    window.onbeforeunload = event => {
      if (isChanged()) {
        event.preventDefault();
        return true
      }
    }
    return () => {
      window.onbeforeunload = undefined;
    }
  });

  return (
      <>
        <Prompt
          message={(location, action) => isChanged() ? "You have unsaved changed. Are you sure you want to move away?" : true}
        />
        <Paper className={classes.paper}>
          <Typography component={'h2'} variant={'h6'} gutterBottom>
            Feedback
          </Typography>
          <MarkdownBlock pVariant="body1">{
`You have been invited to submit feedback for the following Social Finance staff members. If you
were also expecting to submit feedback for someone not listed below, please contact them and ask them to send
you the invitation code.

Each of the questions below has a save button, and the forms auto-save every 30 seconds. There is no need
to submit at the end of the process. On the 10th of June the forms will be locked and submitted to the
respective line managers.

Thank you for your help!`
          }</MarkdownBlock>
          { nominations.map(edge =>
            <FeedbackItem key={edge.node.id} toReview={edge.node} tick={tick}/>
          )}
        </Paper>
      </>
  )
}

const FeedbackWidgetQuery = (props) => {
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query FeedbackWidgetQuery {
            reviews:myReviews {
              nominations {
                edges {
                  node {
                    id
                    reviewee {
                      firstName
                      lastName
                    }
                    ...FeedbackItem_toReview
                  }
                }
              }
            },
          }
        `}
        render={standardResponseComponent(FeedbackWidget, props)}
      />
    );
};

export default FeedbackWidgetQuery;
