import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {commitMutation, createFragmentContainer} from "react-relay";
import graphql from "babel-plugin-relay/macro";

import MUIRichTextEditor from "mui-rte";
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

import MarkdownBlock from "../common/MarkdownBlock";
import environment, {standardResponseHandler} from "../../services/graphql";
import {undefinedIsBlank, useEditorState} from "./questionHook";


const FeedbackFormQuestion = ({question, seq, initialValue, nominationId, tick}) => {
  const dispatch = useDispatch();
  const editorStateHandler = useEditorState(nominationId, question, initialValue);

  useEffect(() => {
    if (editorStateHandler.changed) {
      const markdown = editorStateHandler.markdown;
      updateReview(dispatch, nominationId, question.id, markdown);
      editorStateHandler.saved = markdown;
    }
  }, [dispatch, nominationId, question, tick, editorStateHandler])

  return (
      <>
      <b>Q{seq+1}. {question.title}</b>
      <MarkdownBlock>{question.description}</MarkdownBlock>
      <div style={{border: '1px solid #ccc', padding: '1em', minHeight: '10em', marginTop: '1em', marginBottom: '2em'}}>
        <MUIRichTextEditor label="Start typing..."
                           defaultValue={JSON.stringify(markdownToDraft(undefinedIsBlank(initialValue)))}
          controls={["undo", "redo", "title", "bold", "italic", "numberList", "bulletList", "quote", "save"]}
          draftEditorProps={{
            spellCheck: true,
            stripPastedStyles: true,
          }}
          onChange={(state) => {
            editorStateHandler.state = state;
          }}
          onSave={state => {
              const markdown = draftToMarkdown(JSON.parse(state));
              updateReview(dispatch, nominationId, question.id, markdown)
              editorStateHandler.saved = markdown;
          }}
        />
      </div>
    </>
  )
}

const updateReviewMutation = graphql`
  mutation FeedbackFormQuestionMutation($nominationId: ID!, $questionId: ID!, $value: String!) {
    updateReviewResponse(input: {nominationId: $nominationId, questionId: $questionId, value: $value}) {
      response {
        nomination {
          id
        }
      }
    }
  }
`;

const updateReview = (dispatch, nominationId, questionId, value) => {
  return commitMutation(environment, {
    ...standardResponseHandler(dispatch),
    mutation: updateReviewMutation,
    variables: {nominationId, questionId, value},
  });
}


export default createFragmentContainer(FeedbackFormQuestion, {
  question: graphql`
    fragment FeedbackFormQuestion_question on ReviewFormQuestionNode {
      id
      title
      description
    }
  `,
});