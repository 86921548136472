import {useEffect, useRef} from "react";
import {draftToMarkdown} from "markdown-draft-js";
import {convertToRaw} from "draft-js";
const editorRegistry = {};

export const undefinedIsBlank = (value) => {
  return value === undefined ? '' : value;
}

class StateHandler {
  _markdown = null;
  _state = null;

  constructor(nominationId, question, initialValue) {
    this.nominationId = nominationId;
    this.question = question;
    this.key = `${nominationId}-${question.id}`;
    this.saved = initialValue;
  }

  set state(state) {
    this._state = state;
    this._markdown = null;
  }

  get markdown() {
    if (this._markdown === null) {
      this._markdown = this._state === null ? '' : draftToMarkdown(convertToRaw(this._state.getCurrentContent()))
    }
    return this._markdown
  }

  get changed() {
    const markdown = undefinedIsBlank(this.markdown);
    const saved = undefinedIsBlank(this.saved)
    return saved !== markdown;
  }

}

export const useEditorState = (nominationId, question, initialValue) => {
  const hook = useRef(new StateHandler(nominationId, question, initialValue));
  useEffect(() => {
    const questionId = hook.current.key;
    editorRegistry[questionId] = hook;
    console.log("Registering:", questionId, editorRegistry)
    return () => {
      delete editorRegistry[questionId]
      console.log("De-Registering:", questionId, editorRegistry)
    }
  }, [hook])
  return hook.current;
}

export const useEditors = (nominationId) => {
  return {
    editors: () => {
      const editors = Object.values(editorRegistry).map(obj => obj.current);
      if (nominationId) {
        return editors.filter(obj => obj.nominationId === nominationId);
      } else {
        return editors;
      }
    }
  }
}

