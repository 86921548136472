import React, {useEffect, useState} from "react";
import {createFragmentContainer} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography
} from "@material-ui/core";

import Countable from "countable";

import {makeStyles, withStyles} from "@material-ui/core/styles";
import FeedbackForm from "./FeedbackForm";
import {useEditors} from "./questionHook";


const useStyles = (changed) => makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
  },
  report: {
    display: "flex",
    width: "100%",
    "& span": {
      flex: "auto",
    }
  },
  nominationCount: {
    color: '#333',
  },
  wordCount: {
    whiteSpace: 'nowrap',
    fontStyle: changed ? 'italic' : 'normal',
  }
}));

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
  },
  content: {
    alignItems: "center",
  },
}))(MuiAccordionSummary);

const FeedbackItem = ({toReview, tick}) => {
  const editors = useEditors(toReview.id);
  const [wordCount, setWordCount] = useState(0);
  const [changed, setChanged] = useState(false);
  const classes = useStyles(changed)();

  const user = toReview.reviewee;

  useEffect(() => {
    const myEditors = editors.editors();
    const text = myEditors.map(editor => editor.markdown).join(" ");
    Countable.count(text, ctr => {
      setWordCount(ctr.words);
    })
    setChanged(myEditors.reduce((acc, editor) => editor.changed || acc, false));
  }, [tick, editors, setWordCount, setChanged]);

  return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {/*<Avatar className={classes.avatar} alt="{user.profile.shortName}" src={user.profile.avatar} />*/}
          <Typography className={classes.report} variant='body1'>
            <span>{user.firstName} {user.lastName} - {toReview.form.title}</span>
          </Typography>
          <span className={classes.wordCount}>{wordCount} words {changed && "*"}</span>
        </AccordionSummary>
        <AccordionDetails>
          <FeedbackForm nominationId={toReview.id}
                        form={toReview.form}
                        responses={toReview.responses}
                        tick={Math.floor(tick / 30)} />
        </AccordionDetails>
      </Accordion>
  )
}

export default createFragmentContainer(FeedbackItem, {
  toReview: graphql`
    fragment FeedbackItem_toReview on ExternalReviewerNominationNode {
      id
      reviewee {
        firstName
        lastName
      }
      form {
        id
        title
        ...FeedbackForm_form
      }
      responses {
        ...FeedbackForm_responses
      }
    }
  `
});