import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import 'typeface-roboto';
import App from "./App";
import { getMeta, HEROKU_RELEASE_VERSION } from "./helpers/meta";

const SENTRY_DSN = getMeta('sentry_dsn');
if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: HEROKU_RELEASE_VERSION,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: parseFloat(getMeta('sentry_sample_rate', '1')),
    });
}

ReactDOM.render(
    <App />,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
