import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import clonedeep from 'lodash.clonedeep';
import _ from 'underscore';

const cleanState = (state) => {
    const newState = clonedeep(state);
    for (const key of Object.keys(newState)) {
        const val = newState[key];
        if (!val || val === "") {
            delete newState[key]
        }
    }
    return newState;
}

export const calculateUrlState = (searchString) => {
    const parsed = queryString.parse(searchString);
    return cleanState(parsed);
}

export const useUrlState = () => {
    const location = useLocation();
    const history = useHistory();
    return (state) => {
        const urlState = calculateUrlState(location.search);
        const cleanedState = cleanState(state);
        if(!_.isEqual(urlState, cleanedState)) {
            location.search = queryString.stringify(cleanedState);
            history.replace(location);
        }
    }
}

export const useQuery = (name, defaultValue) => {
    const location = useLocation();
    const history = useHistory();

    const urlState = calculateUrlState(location.search);
    const currentValue = urlState[name] ? urlState[name] : defaultValue;

    return [
        currentValue,
        (newValue) => {
            const urlState = calculateUrlState(location.search);
            const newState = clonedeep(urlState);
            newState[name] = newValue;
            const cleanedState = cleanState(newState);
            if (!_.isEqual(urlState, cleanedState)) {
                location.search = queryString.stringify(cleanedState);
                history.replace(location);
            }
        },
    ];
}

